import ApiService from './ApiService'
import appConfig from '../configs/app.config'
 
export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: appConfig.APIURL + 'weblogin.php',
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        data
    })
}


export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    /*
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
    */
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
